import { Content } from "@/ui";
import { trending_jobs_helper } from "@/helpers/trending_jobs_helper";
import { career_advice_helper } from "@/helpers/career_advice_helper";
import { getRoute } from "@/getters/getRoute";
import { trimText } from "@/functions/trimText";
import { createTitle } from "@/functions/createTitle";
import { mini_carousel_helper } from "@/helpers/mini_carousel_helper";
import { jobs_helper } from "@/helpers/jobs_helper";
import sn from "@/snippets";

export default function Page({ content }) {
  return (
    <>
      <Content items={content} SEOContent={true} />
    </>
  );
}

export async function getStaticProps({}) {
  return {
    props: {
      meta: { title: createTitle("Home") },
      content: [
        {
          component: "Hero",
          props: {
            card1_title: { path: "page.home.component.Hero.card.1.title", placeholder: "Flexible Work" },
            card1_subtitle: { path: "page.home.component.Hero.card.1.subtitle", placeholder: "That’s All About <u>You</u>." },
            card1_cta: { label: "All Jobs", href: "/jobs", variant: "secondary" },
            card1_bg: { path: "page.home.component.Hero.card.1.img", src: "/assets/home/hero/box-1-bg.jpg" },
            card1_img: { path: "page.home.component.Hero.card.1.img", src: "/assets/home/hero/box-1-img.png" },
            card2_title: { path: "page.home.component.Hero.card.2.title", placeholder: "The UK’s Best-Known" },
            card2_subtitle: { path: "page.home.component.Hero.card.2.subtitle", placeholder: "Staffing Agency." },
            card2_cta: { label: "Recruitment Solutions", href: "/recruitment-solutions/why-choose-blue-arrow", variant: "secondary" },
            card2_bg: { path: "page.home.component.Hero.card.2.img", src: "/assets/home/hero/box-2-bg.jpg" },
            card2_img: { path: "page.home.component.Hero.card.2.img", src: "/assets/home/hero/box-2-img.png" },
          },
        },
        // { component: "QuickJobSearch", props: {} },
        {
          component: "RichText",
          props: {
            className: "pt-4 pt-md-5 text-center text-primary h1",
            containerClassName: null,
            body: "Welcome to Blue Arrow the Recruitment Agency."
          },
        },
        {
          component: "TrendingJobs",
          props: {
            title: "Trending Jobs",
            items: trending_jobs_helper.fetch(),
          },
        },
        sn.tileGroups({ page: { tile_groups: "Sectors, Location" } }),
        {
          component: "LatestJobs",
          props: {
            title: { path: "page.home.component.LatestJobs.title", placeholder: "Latest Jobs" },
            visibleCount: 4,
            items: jobs_helper.fetch(),
            filters: true,
          },
        },
        { component: "Divider" },
        {
          component: "FeaturedEmployersCarousel",
          props: {
            title: { path: "page.home.component.FeaturedEmployersCarousel.title", placeholder: "Featured Employers" },
            visibleCount: 4,
          },
        },
        { component: "Divider" },
        {
          component: "PromoSection",
          props: {
            title: { path: "page.home.component.PromoSection.title", placeholder: "Careers Advice" },
            md: 3,
            items: career_advice_helper
              .fetch({ limit: 4, featured: true })
              .map(({ title, content, card_image, url_slug }) => ({
                title,
                img: card_image ?? null,
                description: trimText(content),
                href: getRoute("careerAdviceArticle", { url_slug }),
              })),
          },
        },
        { component: "Divider" },
        {
          component: "MiniCarousel",
          props: {
            title: { path: "page.home.component.MiniCarousel.title", placeholder: "" },
            items: mini_carousel_helper.fetch({
              filter: (i) => i.tags.toLowerCase().includes("home") || i.tags.includes("*"),
            }),
          },
        },
        { component: "Divider" },
        {
          component: "LatestBlogs",
          props: { title: { path: "page.home.component.LatestBlogs.title", placeholder: "Latest Blogs" } },
        },
        { component: "Divider" },
      ],
    },
  };
}
